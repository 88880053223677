import netteForms from 'nette-forms';

let config = {
    closeAfterSelect: true,
    hideSelected: false,
    valueField: 'value',
    labelField: 'text',
    maxItems: 1,
};

/**
 * Display error messages.
 */
netteForms.showFormErrors = function (form, errors) {
	let inputValidation = true;
	/*
	if (form.classList.contains('needs-validation')) {
		form.classList.add('was-validated');
		inputValidation = false;
	}
	 */

	let focusElem,
		elemMessages = {};

	Array.from(form.elements).forEach(elem => {
		elemMessages[elem.id] = {
			messages: [],
			elem,
		};

		elem.classList.remove('is-invalid');

		let invalidFeedback = elem.parentElement.querySelector('.invalid-feedback');
		if (invalidFeedback) {
			invalidFeedback.remove();
		}
	});

	for (let i = 0; i < errors.length; i++) {
		let elem = errors[i].element,
			message = errors[i].message;

		elemMessages[elem.id].messages.push(message);

		if (!focusElem && elem.focus) {
			focusElem = elem;
		}
	}

	for (const [_, item] of Object.entries(elemMessages)) {
		if (item.messages.length > 0) {
			if (inputValidation) {
				item.elem.classList.add('is-invalid');
			}
			let formGroup = item.elem.parentElement;
			let invalidFeedbackElement = document.createElement('div');
			invalidFeedbackElement.classList.add('invalid-feedback');
			invalidFeedbackElement.textContent = item.messages.join('\n');

            let formText = formGroup.querySelector('.form-text');
            if (formText) {
                formText.before(invalidFeedbackElement);
            } else {
                formGroup.appendChild(invalidFeedbackElement);
            }
		}
	}

	if (focusElem) {
		focusElem.focus();
	}
};

let netteInitForm = netteForms.initForm;
netteForms.initForm = function (form) {
	netteInitForm(form);

	Array.from(form.elements).forEach(element => {
		element.addEventListener('input', (_) => {
			element.classList.remove('is-invalid');
		});
	});
};


export async function initAutocomplete(elem) {
    let TomSelect = await import('tom-select');
    let elemConfig = {};

    const preload = elem.dataset.preload || false;
    if (elem.dataset.ajaxUrl) {
        Object.assign(elemConfig, {
            preload: preload,
            load: function (query, callback) {
                let self = this;

                if (preload === 'focus' && self.loading > 1) {
                    callback();
                    return;
                }

                let host = window.location.protocol + "//" + window.location.host;
                let url = new URL(self.input.dataset.ajaxUrl, host);
                url.searchParams.append(self.input.dataset.ajaxQuery || 't', query);

                fetch(url)
                    .then(response => response.json())
                    .then(json => {
                        callback(json);
                        if (preload === 'focus') {
                            self.settings.load = null;
                        }
                    }).catch(() => {
                    callback();
                });

            }
        });
    }

    if (elem.multiple) {
        elemConfig.closeAfterSelect = false;
        elemConfig.hideSelected = true;
    }

    if (elem.dataset.render) {
        let renderTemplates = JSON.parse(elem.dataset.render);
        elemConfig.render = {};
        const regex = /\${([a-zA-Z]+)}/g;

        for (let key in renderTemplates) {
            elemConfig.render[key] = function (item, escape) {
                console.log(renderTemplates[key]);
                return renderTemplates[key].replace(regex, ((match, p1) => {
                    return escape(item[p1] || '');
                }));
            };
        }
    }

    new TomSelect.default(elem, Object.assign({}, config, elemConfig));
}


export default netteForms;
