import 'bootstrap';
import '../scss/idm.scss';
import {Tooltip, Modal} from 'bootstrap';

import netteForms, {initAutocomplete} from './form.js';
import naja from 'naja';
import {LoadingIndicatorExtension} from './naja/spinner.js';
import {DownloadFileExtension} from "./naja/download.js";

naja.formsHandler.netteForms = netteForms;
netteForms.initOnLoad();

window.bootstrap = {
    Modal,
};

naja.formsHandler.netteForms = netteForms;

naja.registerExtension(new LoadingIndicatorExtension());

naja.registerExtension(new DownloadFileExtension());
naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    if (event.detail.snippet) {
        event.detail.snippet.querySelectorAll('form input.autocomplete, form select.autocomplete').forEach(initAutocomplete);
    }
});
naja.initialize();

function showPassword(elem, show) {
    let x = elem.closest('.input-group');
    if (!x) {
        return;
    }

    let input = x.querySelector('input');
    let svgIconLink = elem.parentElement.querySelector('svg > use');
    let svgLinkHref = svgIconLink.getAttribute('xlink:href').split('.svg#')[0];
    if (show) {
        svgIconLink.setAttribute('href', svgLinkHref + '.svg#bi-eye-slash');
        input.type = "text";
    } else {
        svgIconLink.setAttribute('href', svgLinkHref + '.svg#bi-eye');
        input.type = "password";
    }
}

document.querySelectorAll('form a.show-password, form span.show-password').forEach(elem => {
    elem.addEventListener('mousedown', function () {
        showPassword(elem, true);
    });
    elem.addEventListener('mouseup', function () {
        showPassword(elem, false);
    });
    elem.addEventListener('mouseout', function () {
        showPassword(elem, false);
    });
    elem.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
    });
    elem.addEventListener('touchstart', function () {
        showPassword(elem, true);
    });
    elem.addEventListener('touchmove', function () {
        showPassword(elem, false);
    });
    elem.addEventListener('touchend', function () {
        showPassword(elem, false);
    });
    elem.addEventListener('touchcancel', function () {
        showPassword(elem, false);
    });
});

document.querySelectorAll('form input.autocomplete, form select.autocomplete').forEach(initAutocomplete);
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
